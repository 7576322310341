import { exWorkOrder } from "@/api/order";
import { MessageBox, Message } from "element-ui";
const openWord = function (id, value, context) {
  if (!id || !value) {
    return;
  }
  POBrowser.openWindow(
    "/PgOffice/simpleWord",
    "width=980px;height=800px;",
    JSON.stringify({ orderId: id, fileDir: decodeURIComponent(value) })
  );
  window.PageOfficeCbFn = () => {
    exWorkOrder({ id: id, workStatus: 3 })
      .then((res) => {
        Message("已完成受理");
        context.search();
      })
      .catch((e) => {})
      .finally(() => {
        window.PageOfficeCbFn = () => {};
        window.PageOfficeCloseFn = () => {};
      });
  };
  window.PageOfficeCloseFn = () => {};
};

const getOperateConfig = (row, context) => {
  const { workStatus, workFlow, wordDir } = row;
  if (workStatus === 2) {
    const handleConfirm = (row_) => {
      if (workFlow == 2) {
        window.PageOfficeCbFn = (value) => {
          openWord(row_.id, value, context);
        };
        window.PageOfficeCloseFn = () => {
          openWord(row_.id);
        };
        POBrowser.openWindow(
          "/PgOffice/editorExcel",
          "width=980px;height=800px;",
          encodeURI(JSON.stringify({ orderId: row_.id }))
        );
      }
      if (workFlow == 3) {
        openWord(row_.id, wordDir, context);
      }
    };

    return {
      class: "table-btn " + (workFlow == 3 ? "addYZ_btn" : "green-button"),
      label: workFlow == 3 ? "加盖印章" : "待确定",
      handle: (row) => {
        handleConfirm(row);
      },
    };
  }

  const handleStartOrContinue = (row) => {
    context.$router.push({
      name: "orderAcceptance",
      params: {
        id: row.id,
        instrumentName: encodeURI(row.instrumentName),
        workFlow: row.workFlow,
        data: row,
      },
    });
  };

  return {
    class:
      workFlow === 0 ? "table-btn primary-button" : "table-btn warning-buttton",
    label: workFlow === 0 ? "开始检测" : "继续检测",
    handle: (row) => {
      handleStartOrContinue(row);
    },
  };
};

export default (context) => {
  return {
    columns: [
      {
        prop: "businessNo",
        label: "业务编号",
        width: 10,
      },
      {
        prop: "instrumentName",
        label: "仪器名称",
        width: 15,
      },
      {
        prop: "factoryNo",
        label: "出厂编号",
        width: 10,
      },
      {
        prop: "specificationsType",
        label: "型号规格",
        width: 10,
      },
      {
        prop: "client",
        label: "委托单位",
        width: 20,
      },
      {
        prop: "workTime",
        label: "受理日期",
        width: 10,
      },
    ],
    operate: [
      {
        class: (row) => {
          return getOperateConfig(row, context).class;
        }, //操作按钮显示类型
        label: (row) => {
          return getOperateConfig(row, context).label;
        }, //按钮显示文字
        handle: (row) => {
          getOperateConfig(row, context).handle(row);
        },
      },
    ],
    operateWith: 8,
  };
};
